import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Button from '@components/Button';
import useIsomorphicLayoutEffect from '@utility/useIsomorphicLayoutEffect';
import Link from '@utility/Link';
import PortableText from '@utility/PortableText';
import CodeInput from '@components/CodeInput';
import ScrollInView from '@utility/ScrollInView';
import cn from 'classnames';
import * as styles from './styles';

const ContactBlock = ({ showDealers, inView, bottomBorder }) => {
  const supportData = useStaticQuery(
    graphql`
      query {
        allSanitySupport {
          edges {
            node {
              id
              _rawEmailText(resolveReferences: { maxDepth: 10 })
              address1
              address2
              contactHeading
              contactSubHeading
              location
              visitHours
              visitHeading
              visitSubHeading
              visitEmbed {
                codeInput {
                  code
                }
                buttonText
              }
              visitEmbed2 {
                codeInput {
                  code
                }
                buttonText
              }
              phoneNumbers {
                _key
                label
                number
              }
              liveChatToggleSwitch
            }
          }
        }
      }
    `,
  );

  const supportNode = supportData.allSanitySupport.edges[0].node;
  const chatRef = useRef();
  const [activeEmbedIndex, setActiveEmbedIndex] = useState(null)

  useIsomorphicLayoutEffect(() => {
    if (chatRef.current) {
      chatRef.current.addEventListener('click', () => {
        $zopim.livechat.window.show();
      });
    }
  }, []);

  const changeActiveIndex = (index) => {
    if (index === activeEmbedIndex) {
      setActiveEmbedIndex(null); // => Close the open embed when the same button is clicked
      return;
    }
    setActiveEmbedIndex(index);
  }

  return (
    <div>
      <ScrollInView skip={!inView}>
        <div className="wrapper" id="visit">
          <div className='pb-14 md:pb-24 pt-10 md:pt-18 border-b border-mono-400'>
            <div className="row">
              <div className="col-12 sm:col-4 md:offset-1">
                <h2 className="sm-down:mb-6 type-sans-830 md:type-sans-930">
                  {supportNode?.visitHeading}
                </h2>
                {!!supportNode.visitSubHeading && (
                  <p className="sm-down:mb-6 sm:ml-1">
                    {supportNode.visitSubHeading}
                  </p>
                )}
              </div>
              <div className="col-12 sm:col-6">
                <div className="mb-8 text-mono-500">
                  <p>{supportNode.location}</p>
                  <p>{supportNode.address1}</p>
                  <p className="mb-6">{supportNode.address2}</p>
                  <p>{supportNode.visitHours}</p>
                </div>
                <div className='flex flex-col md:flex-row gap-10 md-down:gap-4'>
                  <Button
                    className="btn btn-default btn-default--max-width btn-black "
                    onClick={() => changeActiveIndex(1)}
                    aria-label={supportNode.visitEmbed?.buttonText}
                  >
                    {supportNode.visitEmbed?.buttonText}
                  </Button>
                  <Button
                    className="btn btn-secondary btn-secondary--upper btn-secondary--max-width w-full"
                    onClick={() => changeActiveIndex(2)}
                    aria-label={supportNode.visitEmbed2?.buttonText}
                  >
                    {supportNode.visitEmbed2?.buttonText}
                  </Button>
                </div>
                <div className="inline xl-down:flex xl-down:flex-col-reverse">
                  {!!supportNode.visitEmbed?.codeInput?.code && (
                    <div
                      className={cn(styles.visitForm, {
                        [styles.isActive]: activeEmbedIndex === 1,
                      })}
                    >
                      <CodeInput data={supportNode.visitEmbed.codeInput.code} />
                    </div>
                  )}
                  {!!supportNode.visitEmbed2?.codeInput?.code && (
                    <div
                      className={cn(styles.visitForm, {
                        [styles.isActive]: activeEmbedIndex === 2,
                      })}
                    >
                      <CodeInput data={supportNode.visitEmbed2.codeInput.code} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollInView>
      <ScrollInView skip={!inView}>
        <div className="wrapper" id="contact">
          <div
            className={`py-14 md:py-24 ${
              bottomBorder ? 'border-b border-mono-400' : ''
            }`}
          >
            <div className="row">
              <div className="col-12 sm:col-4 md:offset-1">
                <h2 className="mb-2 type-sans-830 md:type-sans-930">
                  {supportNode?.contactHeading}
                </h2>
                {!!supportNode.contactSubHeading && (
                  <p className="sm-down:mb-6 sm:ml-1">
                    {supportNode.contactSubHeading}
                  </p>
                )}
              </div>
              <div className="col-12 sm:col-6">
                <div className="mb-4 text-mono-500">
                  {supportNode._rawEmailText.richText && (
                    <div className="mb-8 mt-1 richtext-content">
                      <PortableText
                        blocks={supportNode._rawEmailText.richText}
                      />
                    </div>
                  )}
                </div>
                {supportNode.liveChatToggleSwitch && (
                  <Button
                    ref={chatRef}
                    className="btn btn-default btn-default--max-width btn-black"
                    aria-label="Start Live Chat"
                  >
                    Start Live Chat
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </ScrollInView>
    </div>
  );
};

ContactBlock.propTypes = {
  showDealers: PropTypes.bool,
};

ContactBlock.defaultProps = {
  showDealers: false,
};

export default ContactBlock;
